import React from 'react'
import MainLayout from "../layouts/MainLayout/MainLayout"

const ErrorPage = () => (
  <MainLayout>
        {/* <Seo title="404: Not found" /> */}
        <h1>404</h1>
        <p>Strona nie istnieje</p>
  </MainLayout>
)

export default ErrorPage
